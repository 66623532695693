import { render, staticRenderFns } from "./FeedAdCard.vue?vue&type=template&id=9b7e1e7e&scoped=true&"
import script from "./FeedAdCard.vue?vue&type=script&lang=js&"
export * from "./FeedAdCard.vue?vue&type=script&lang=js&"
import style0 from "./FeedAdCard.vue?vue&type=style&index=0&id=9b7e1e7e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b7e1e7e",
  null
  
)

export default component.exports